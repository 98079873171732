@tailwind base;

@tailwind components;

@tailwind utilities;


/* Styling for Toastify */
:root {
  --toastify-toast-padding: 1rem;
  --toastify-toast-min-height: 2.25rem;
  --toastify-toast-max-height: none;
}

.Toastify__toast-icon {
  padding-right: 0.25rem;
  padding-top: 0.1rem;
  align-self: start;
}

.Toastify__close-button {
  padding-top: 0.25rem;
  padding-right: 0.125rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */ 
}

body {
  overflow: hidden!important;
}

/* STYLE REACT-PHONE-NUMBER-INPUT */
div > .PhoneInputInput {
  height: 100%;
  padding-left: 8px;
  outline: none;
}
